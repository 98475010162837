<!-- 缩略图列表，展示为一排缩略图，一般用于上传图片或显示一组图片时 -->

<template>
    <ul class="thumbnail-list" v-if="listAlready"
        :class="{block: showLine}">

        <template v-for="(image, index) in images">
            <!-- 如果数据的 percentage 属性为小于100的数字，则显示一个进度动画 -->
            <li v-if="image.percentage < 100" :style="sizeStyle"
                :key="image.name || image.path || image">

                <el-progress :percentage="parseInt(image.percentage) || 0" type="dashboard"></el-progress>
            </li>

            <li v-else-if="type==='text'" class="text"
                :key="'t'+(image.path || image.name || image.url || image)"
                @click.stop="handleThumbClick(index)">

                <div class="name-tag" v-if="textType=='index'">附件{{ index+1 }}</div>
                <div class="name-tag" v-else>{{ image.name || image.cname || image }}</div>
            </li>

            <!-- 否则，即显示图片 -->
            <li v-else
                :key="'i'+(image.path || image.name || image.url || image)"
                :style="sizeStyle + 'background-image: url(' + encodeURI(image.url || image.path || image) + ')'"
                @click.stop="handleThumbClick(index)">

                <span v-if="!image.isImage">{{ image.cname || image.name }}</span>

                <i class="el-icon-delete" v-if="showDelete"
                    @click.stop="handleDeleteClick(index)"></i>
            </li>
        </template>
    </ul>
</template>

<script>

export default {
    name: 'ThumbnailList',
    props: {
        images: {
            type: Array,
            require: true,
            default: function () {
                return []
            }
        },
        showDelete: {
            type: Boolean,
            default: false
        },
        read: { // 可查看大图
            type: Boolean,
            default: true
        },
        size: Number,
        type: {
            type: String,
            default: "image"
        },
        textType: {
            type: String,
            default: ""
        },
        showLine: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        listAlready () {
            return (this.images instanceof Array) && this.images.length
        },

        sizeStyle () {
            const size = this.size || (this.showDelete ? 120 : 46);
            return `width: ${size}px; height: ${size}px;`;
        }
    },

    watch: {
        images: {
            immediate: true,
            deep: true,
            handler (list) {
                list.forEach(it => {
                    it.isImage = it.isImage || it.path?.endsWith(".png") ||
                        it.path?.endsWith(".jpg") ||
                        it.path?.endsWith(".jpeg") ||
                        it.path?.endsWith(".gif");
                    it.cname = it.name || it.path.slice(it.path.lastIndexOf(".")+1)+"文件"
                });
            }
        }
    },

    methods: {

        handleThumbClick(index) {
            if (!this.read) return this.$message({
                message: "您没有查看权限",
                type: "warning"
            });
            // console.log(image);
            // this.$hevueImgPreview(image.url || image.path || image)
            const item = this.images[index];
            if (item.isImage) {
                const list = this.images.filter(it =>it.isImage);
                const index = list.indexOf(item);
                this.$viewerApi({
                    images: list.map(item => encodeURI(item.url || item.path || item)),
                    options: {
                        initialViewIndex: index
                    }
                })
            }
            else window.open(encodeURI(item.url || item.path || item))
        },

        handleDeleteClick(index) {
            const item = this.images[index]
            this.$confirm(`您确定要删除这${item.isImage?"张图片":"个文件"}吗？`, "提示")

            .then(() => {
                this.$emit("delete", index)
            })

            .catch(reason => {
                console.log("取消删除", reason)
            })
        },

        // showFileName (file) {
        //     const isImage = file.path.endsWith(".png") ||
        //         file.path.endsWith(".jpg") ||
        //         file.path.endsWith(".jpeg") ||
        //         file.path.endsWith(".gif");
            
        //     if (isImage) return "";
        //     else return file.name;
        // }
    },
}
</script>

<style lang='scss' scoped>
    ul {
        &:not(.block) {
            display: flex;
            flex-wrap: wrap;
            min-width: 100%;

        }
        margin-bottom: -10px;

        &:nth-child(n+2) {
            margin-top: 10px;
        }
    }
    
    li {
        display: flex;
        align-items: center;
        justify-content: center;
        &:not(.text) {
            border-radius: 2px;
            background-color: whitesmoke;
            background-size: cover;
            background-position: center;
        }
        margin-right: 10px;
        margin-bottom: 10px;

        &:not(.unclickable) {
            cursor: pointer;
        }

        // &.show-delete {
            // width: 120px;
            // height: 120px;
            position: relative;
        // }

        &:hover i {
            opacity: 1;
        }

        // + li {
        //     margin-left: 10px;
        // }
    }

    .name-tag {
        color: #FD9A17;
    }
    
    i {
        position: absolute;
        top: 0; right: 0;
        padding: 6px;
        background-color: rgba($color: white, $alpha: .75);
        border: 1px solid rgba($color: #000000, $alpha: .2);
        border-radius: 0 0 0 6px;
        font-size: 16px;
        opacity: 0;
        transition: opacity .25s;
    }

    .attachment-list {
        li {
            background-color: unset;
            .name-tag {
                color: inherit;

                padding-left: 20px;
                background: url(data:img/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPCAMAAAA1b9QjAAAAaVBMVEUAAABxcXFmZmZmZmZqampnZ2dnZ2dnZ2dtbW1mZmZmZmZmZmZnZ2dnZ2dnZ2dnZ2doaGiamppnZ2dmZmZoaGhnZ2dnZ2doaGhpaWlqampnZ2dmZmZnZ2dnZ2dnZ2dqampra2tnZ2dmZmaPmcscAAAAI3RSTlMACN3HGdiEVQ696MG4s6ZcJAONdWZNRTUpHtCVfGFAMBOrqlewzgQAAACMSURBVBjTVc5LEoIwEEXRS4IECAQjyE8FdP+LNA1SKe+gB6d68GCoSm0gKSaOBtUnq87AXNJD7II8BGp+VIKQjSQ3vZLbJ7x2ah30KpAWmgP5+gH3nVrotIfkj1yH0E1oCtQwaoSqQCbQOuNLIl2W7E2ugHNhb12KcUSSNjVyUl0NfjOqI9YUpfqM8AVxagau3fH2EgAAAABJRU5ErkJggg==)
                    left center no-repeat;
            }
        }
    }
    
</style>